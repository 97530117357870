import { BackgroundImage } from "./BackgroundImage";
import { Balloon, GreenBalloon, OrangeBalloon, PinkBalloon, RedBalloon } from "./Balloon";
import { disableTouch, setEvent } from "./Platform";
import { PEWGame, PlayAndWinPlatform } from "./PlayAndWinPlatform";

interface IGameConfig {
    backgroundColor: string;
    
    lives: number;

    startSpeed: number;
    speedMethod: 'score' | 'time' | 'none';
    speedIncrease: number;
    speedScoreInterval: number,
    
    // levelIncrease: number;
    
    startSpawnTime: number;
    minimumSpawnTime: number;
    spawnIncrease: number;

    path: string;
    assetUrl: string;
    balloonTypes: number;

    scores?: string;

    balloonScores: Array<number>;
    overlayColor: string;
	pauseMessage: string;
}

const DefaultConfig = {
    backgroundColor: '#b6eeff',
    
    balloonTypes: 4,

    startSpeed: 1,
    speedMethod: 'time',
    speedIncrease: 0.02,
    speedScoreInterval: 100,

    startSpawnTime: 1200,   // ms
    minimumSpawnTime: 200,  // ms
    spawnIncrease: 10,

    path: 'default',
    assetUrl: '/assets/',

    lives: 3, // not changeable without html changes
    // levelIncrease: 50,
    scores: '',
    balloonScores: [10,15,20,30],
    overlayColor: '#FAFAFAB2',
    pauseMessage: 'Spel gepauzeerd'

    
} as IGameConfig;
const GameConfig1 = {
    backgroundColor: '#b6eeff',
    
    balloonTypes: 4,

    startSpeed: 1,
    speedMethod: 'time',
    speedIncrease: 0.02,
    speedScoreInterval: 100,

    startSpawnTime: 1200,   // ms
    minimumSpawnTime: 200,  // ms
    spawnIncrease: 10,

    path: 'default',
    assetUrl: 'https://gamecdn.playenwin.com/balloon/',

    lives: 3, // not changeable without html changes
    balloonScores: [10,15,20,30],
    scores: '',
    overlayColor: '#FAFAFAB2',
    pauseMessage: 'Spel gepauzeerd'
    // levelIncrease: 50,
} as IGameConfig;

const GameConfig2 = {
    backgroundColor: '#b6eeff',
    
    balloonTypes: 4,

    startSpeed: 1,
    speedMethod: 'score',
    speedIncrease: 0.5,
    speedScoreInterval: 1000,

    startSpawnTime: 1200,   // ms
    minimumSpawnTime: 200,  // ms
    spawnIncrease: 10,

    path: 'default',
    assetUrl: 'https://gamecdn.playenwin.com/balloon/',

    lives: 3, // not changeable without html changes
    balloonScores: [10,15,20,30],
    scores: '',
    // levelIncrease: 50,
    overlayColor: '#FAFAFAB2',
    pauseMessage: 'Spel gepauzeerd'

} as IGameConfig;


const ImageList = [
    'plane-sprite.png',
    'full-balloon.svg',
    'wrong-balloon.svg',
    'explosion-sprite.png',
    'green.png',
    'red.png',
    'orange.png',
    'pink.png',
    'cord.svg',
    'cloud01.png',
    'cloud02.png',
    'cloud03.png',
    'bird.svg'
];

type BackgroundTypes = 'planus' | 'cloud' | 'bird';

export class Game implements PEWGame{
    state:  'init' | 'ready' | 'playing' | 'gameover';
    debug: boolean;
    isPaused: boolean;
    balloonId: number;
    score: number;
    playElement: HTMLElement;
    scoreElement: HTMLElement;
    livesElement: HTMLElement;
    canvasElement: HTMLElement;
    lifeElements: any[];
    screenHeight: number;
    screenWidth: number;
    intervalId: any;
    spawnTime: number;
    balloonSpeedMultiplier: number;
    lives: number;
    lastCheckedScore: number;
    lastcheckedScoreSpeed: number;
    scoreElem: any;
    config: IGameConfig;
    startTime: number;
    private images: Array<HTMLImageElement> = [];
    private imageLoadCount = 0;
    private pew: PlayAndWinPlatform;
    constructor() {
        this.debug = false; // turn true if you wan to log
        this.isPaused = false;
        this.balloonId = null
        this.score = null;
        this.playElement = document.getElementById('start-btn');
        this.scoreElement = document.getElementById('score-container');
        this.livesElement = document.getElementById('lives-container');
        this.canvasElement = document.getElementById('canvas');
        this.lifeElements = [];
        this.screenHeight = null;
        this.screenWidth = null;
        this.intervalId = null;
        this.spawnTime = null;
        this.balloonSpeedMultiplier = null;
        this.lives = null;
        this.lastCheckedScore = null;
        this.lastcheckedScoreSpeed = null;
        this.state = 'init';

        if (window.location.search.search('debug') !== -1) {
            this.debug = true;
        }

        disableTouch();
        this.loadConfig();
        this.setStyles();

        // Prepare game
        this.preload();
    }

    /**
     * Initialzie the game
     */
    initGame (){
        
        this.balloonId = 0;
        this.isPaused = true;
        this.score = 0;
        this.spawnTime = this.config.startSpawnTime;
        this.balloonSpeedMultiplier = this.config.startSpeed;
        this.lives = this.config.lives;
        this.lastCheckedScore = 0;
        this.lastcheckedScoreSpeed = 0;
        this.screenHeight = document.getElementById('canvas').clientHeight;
        this.screenWidth = document.getElementById('canvas').clientWidth;
        this.scoreElem = document.getElementById('score-count');

        this.lifeElements.push(document.getElementById('lives-count3'));
        this.lifeElements.push(document.getElementById('lives-count2'));
        this.lifeElements.push(document.getElementById('lives-count1'));
        this.imageSpawner('cloud', 3, true, true);
        this.imageSpawner('planus', 1, false, false);
        this.imageSpawner('bird', 1, false, false);
        
        if(this.debug){
            console.log('__DEBUG MODE ENABLED__');
        }else{
            this.gameLog('__DEBUG MODE DISABLED__');
        }
        this.pew = new PlayAndWinPlatform();
        this.pew.init(this,
            () => {
                this.state = 'ready';
                this.pew.ready();
            }
        );
        if(this.debug) {

            top.window.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.code === 'KeyP') {
                    this.pause();
                }
            }
            );
        }
        setEvent(document, this.handleClick.bind(this), this.handleTap.bind(this));

       document.addEventListener('blur', (e) => {
            this.pause();
            // Pause on blur?
       }, { passive: false });

       window.addEventListener("visibilitychange", (e) => {
           if (document.visibilityState === 'hidden' ) {
               this.pause();
           } 
       }, { passive: false });
       
       // Safari
       document.addEventListener("pagehide", event => {
           this.pause();
       }, { passive: false });

        
    };
    private handleTap(e: TouchEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.handleClickTap(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }

    private handleClick(e: MouseEvent ) {
        e.preventDefault();
        e.stopPropagation();
        this.handleClickTap(e.clientX, e.clientY);
    }

    private handleClickTap(x: number, y: number) {
        if (this.isPaused) {
            this.pause();
            return;
        }
    }

    pause() {
        if (this.isPaused) {
            document.getElementById('overlay').style.display ='none';
            this.balloons.forEach(balloon => {
                balloon.pause(false);
            })
            this.images.forEach(image => {
                console.log(image);
            });
            this.isPaused = false;
            window.setTimeout(() => {
            this.updateGame();
        }, this.spawnTime);
        } 
        else {
            if (this.state !== 'playing') {
                return;
            }
            document.getElementById('overlay').style.display ='block';
            this.balloons.forEach(balloon => {
                balloon.pause(true);
            })
            this.isPaused = true;
        }
    }

    private loadConfig() {
        const urlParams = new URLSearchParams(window.location.search);
        switch (urlParams.get('v')) {
          case '1':
            this.config = GameConfig1;
            break;
          case '2':
            this.config = GameConfig2;
            break;
          default:
            this.config = DefaultConfig;
        }

      
      
      
        // Patch settings
      if (urlParams.get('s')) {
        const settings = atob(urlParams.get('s')).split('\n').filter(s => s.trim() !== '').map(s => {
          return { name: s.split('=')[0].trim(), value: s.split('=')[1].trim() };
        });
        this.gameLog(settings);
        settings.forEach(s => {
          if (!isNaN(+s.value)) {
            this.config[s.name] = +s.value;
          } else if (s.value === 'true') {
            this.config[s.name] = true;
          } else if (s.value === 'false') {
            this.config[s.name] = false;
          } else {
            this.config[s.name] = s.value;
          }
        }
        )
      }

      if (this.config.scores) {
        this.config.scores.split(',').map((score, index) => {
            if (index < this.config.balloonScores.length) {
                this.config.balloonScores[index] = +score;     
            }

        })
      }
      console.log(this.config.balloonScores);
    }

    private preload() {
        const cache = this.debug ? '?' + Date.now() : '';
        let i= 0;
        ImageList.forEach(imagename => {
            i++;
            const j = i;
            let img = new Image();
            img = new Image();
            img.src = this.config.assetUrl + this.config.path + '/' + imagename + cache;
            img.onload = (e) => this.checkImages(j, imagename);
            this.images.push(img);
        });
    }

    checkImages(i, img) {
        
        this.imageLoadCount++;
        console.log('image',i, img, this.imageLoadCount,  this.images.length)
        if (this.imageLoadCount === this.images.length) {
            console.log('ALL IMAGES LOADED ');
            this.initGame();
        }
    }

    private setStyles() {
        const sheet = document.createElement('style');
        const path = this.config.assetUrl + this.config.path + '/';        
        sheet.innerHTML = `
            body { background-color: ` + this.config.backgroundColor + `}
            .planus {background-image: url("`+ path + `plane-sprite.png")}
            .life {background-image: url("`+ path + `full-balloon.svg")}
            .life.lost {background-image: url("`+ path + `wrong-balloon.svg")}
            .explosion {background-image: url("`+ path + `explosion-sprite.png")}
            .balloon.green {background-image: url("`+ path + `green.png")}
            .balloon.red {background-image: url("`+ path + `red.png")}
            .balloon.orange {background-image: url("`+ path + `orange.png")}
            .balloon.pink {background-image: url("`+ path + `pink.png")}
            .balloon:after {background-image: url("`+ path + `cord.svg")}

            .cloud.c0 {background-image: url("`+ path + `cloud01.png")}
            .cloud.c1 {background-image: url("`+ path + `cloud02.png")}
            .cloud.c2 {background-image: url("`+ path + `cloud03.png")}

            .bird {background-image: url("`+ path + `bird.svg")}

            
        `;
        document.body.appendChild(sheet);

        const elmOverlay = document.getElementById('overlay');
        const elmOverlayContent = document.getElementById('overlayContent');
        
        elmOverlay.style.backgroundColor = this.config.overlayColor;
        elmOverlayContent.innerHTML = this.config.pauseMessage;
        
        if (this.debug) {
            console.log('test');
            document.getElementById('debugInfo').style.display ='block';
            document.getElementById('debugSpeed').innerHTML = this.balloonSpeedMultiplier?.toString() || '';
        }
    }


    private readyToPlay() {
        
    }

    public play() {
        this.startGame();
    }

    startGame() {
        if (this.state !== 'ready') {
            return;
        }
        
        // Destroy old balloons
        this.balloons.forEach(balloon => {
            balloon.destroy();
        })
        this.balloons = [];
        this.score = 0;
        this.lives = this.config.lives;
        this.spawnTime = this.config.startSpawnTime;
        this.balloonSpeedMultiplier = this.config.startSpeed;
        this.lastCheckedScore = 0;
        this.lastcheckedScoreSpeed = 0;
        this.state = 'playing';
        this.scoreElem.innerHTML = 0;
        this.lifeElements.forEach(e => {
            e.classList.remove("lost");
        })
        this.gameLog(this.config.startSpawnTime);
        this.isPaused = false;
        this.updateGame();
        
        
        // this.intervalId = window.setInterval(, );
        // this.pew.gamestarted();
    }


    updateScore(score){
        this.pew.updatescore(score);
        this.scoreElem.innerHTML = score;
    };
    private balloons: Array<Balloon> = [];
    /**
     * The update function is based on spawnTime
     */
    updateGame(){
        if (this.debug) {
            document.getElementById('debugSpeed').innerHTML = this.config.speedMethod +': increase=' + this.config.speedIncrease + ': current speed:' + this.balloonSpeedMultiplier?.toString() || '';
            document.getElementById('spanSpeed').innerHTML = this.spawnTime?.toString() || '';
        }
        if (this.isPaused) {
            return;
        }
        this.gameLog('update' + this.spawnTime);
        
        // first check if the player is already dead or not
        this.checkifGameOver();

        // then see if the player needs to get a multieplier or not
        this.checkForMultiplier();

        // check if we need to increase spawnspeed
        this.checkForLevelUp();

        if (this.state !== 'playing') {
            return;
        }

        // create a random balloon
        let balloon = this.getRandomBalloon();

        // spawn the balloon on our 'canvas'
        balloon.spawnOnCanvas(this.balloonSpeedMultiplier, this.balloonId);
        this.gameLog('speed' + this.balloonSpeedMultiplier);
        this.balloons.push(balloon);
        this.balloonId++;

        

        window.setTimeout(() => {
            this.updateGame();
        }, this.spawnTime);
    };

    /**
     * If the player has a score higher than 200 the spawn time will increase by 50 ms
     */
    checkForLevelUp() {
        if (this.config.spawnIncrease) {
            this.spawnTime = this.spawnTime - this.config.spawnIncrease;
            this.spawnTime = Math.max(this.spawnTime, this.config.minimumSpawnTime);
            // clearInterval(this.intervalId);
            // this.intervalId = window.setInterval(this.updater.bind(this), this.spawnTime);
        }
        return;
        // if(this.score - this.lastcheckedScoreSpeed > this.config.levelIncrease){
        //     this.lastcheckedScoreSpeed = this.score;
        //     if(this.spawnTime > this.config.minimumSpawnTime){
        //         this.spawnTime -= this.config.levelIncrease;
        //     }

            

        //     this.gameLog('Current spawntime increased to : ' + this.spawnTime + ' ms');
        // }
    }

    /**
     * if the player has a score higher than 1000 the multiplier for the balloon speed wil increase by 0.5
     */
    checkForMultiplier() {
        switch (this.config.speedMethod) {
            case 'none':
                 break;
            case 'time':
                this.balloonSpeedMultiplier += this.config.speedIncrease
                break;
            case 'score':
                if(this.score - this.lastCheckedScore > this.config.speedScoreInterval){
                    this.lastCheckedScore = this.score;
                    this.balloonSpeedMultiplier += this.config.speedIncrease;
                    this.gameLog("Current balloonSpeed Multiplier increased to: " + this.balloonSpeedMultiplier);
                }
                break;
        }
        this.gameLog('speed' + this.balloonSpeedMultiplier);
    }

    /**
     * Checks if the player is game over or not.
     */
    checkifGameOver() {
        this.gameLog('Check gameover');
        if(this.lives <= 0) {
            this.gameover();
        }
    }

    gameover() {
        clearInterval(this.intervalId);
        this.pew.gameover(this.score);
        this.state = "gameover";
        this.balloons.forEach(balloon => {
            balloon.destroy();
        })
        window.setTimeout(
            () => {this.prepare();},
            750
        );
    }

    prepare() {
        // Destroy old balloons
        
        this.scoreElem.innerHTML = 0;
        this.lifeElements.forEach(e => {
            e.classList.remove("lost");
        })
        this.state = 'ready';
        this.pew.ready();
    }

    /**
     * Get a random baloon to spawn
     */
    getRandomBalloon(): Balloon {
        const random = Math.random();
        const rand = Math.floor(random * (this.config.balloonTypes));
        switch(rand){
            case 0: return new GreenBalloon(this);
            case 1: return new OrangeBalloon(this);
            case 2: return new RedBalloon(this);
            case 3: return new PinkBalloon(this);
            default: return new GreenBalloon(this);
        }
    }

    /**
     * Removes life from player
     */
    removeLife(){
        
      if (this.lives >= 1) {
        this.lives--;
        this.lifeElements[this.lives].classList.add("lost");
        this.checkifGameOver();
      } 
    }

    /**
     * Sets the spawntime for mages on the background, bird plane cloud
     * @param {string} type
     * @param {int} amount
     * @param {bool} directSpawn
     */
    imageSpawner(type: BackgroundTypes, amount, directSpawn, diffentSpawnPos){
        var spawnTime = 0
        if(!directSpawn){
            spawnTime = Math.floor(Math.random() * 16)+ 5;
            spawnTime *= 1000;
        }
        for(let i = 0; i < amount; i++){
            this.setImageTimout(type, amount, directSpawn, spawnTime, i, diffentSpawnPos);
        }
    }

    /**
     * Spawns the actual images
     * @param {string} type
     * @param {int} amount
     * @param {boolean} directSpawn
     * @param {int} spawnTime
     * @param {int} i
     */
    setImageTimout(type: BackgroundTypes, amount, directSpawn, spawnTime, i, diffentSpawnPos) {
    var game = this;
    // return;
    setTimeout(function(){
        var spawnthis = null;
        switch(type){
            case 'cloud':
                if(amount == 1){
                    i = Math.floor(Math.random() * Math.floor(3));
                }
                spawnthis = new BackgroundImage(game, type, game.generateRandomNumber(0.3, 1)/*Math.floor(Math.random() * Math.floor(2))+1*/, diffentSpawnPos, i);
                break;
            case 'bird':
                spawnthis = new BackgroundImage(game, type, 1, diffentSpawnPos);
                break
            case 'planus':
                spawnthis = new BackgroundImage(game, type, 4, diffentSpawnPos);
                break;
        }

        spawnthis.spawnOnCanvas();
    }, spawnTime);
    }
    
    generateRandomNumber(min, max){
        return Math.random() * (max - min) + min;
    }

    endGame(){

    };

    gameLog (text){
        if(this.debug){
            console.log(text);
        }
    }

    
}
