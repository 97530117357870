import { Game } from "./Game";
import { setEvent } from "./Platform";

/**
 * Generic Baloon class
 */
export class Balloon {
    positionX: number;
    positionY: number;
    color: string;
    speed: number;
    points: number;
    type = 'normal';
    intervalId: number;
    game: Game;
    el = null;
    balloonId = null;
    explosion = null;
    explosionTimout = null;
    paused = false;
    state: 'init' | 'active' | 'removed';
    constructor(game: Game){
      this.positionX = this.generateRandomXPos();
      this.positionY = -150;
      this.speed = null;
      this.type = 'normal';
      this.intervalId = null;
      this.game = game;
      this.el = null;
      this.balloonId = null;
      this.explosion = null;
      this.explosionTimout = null;
      this.state = 'init';
    }

    /**
     * allows the speed of the baloom to be multiplied
     * @param {int} multiplier
     */
    setMultipliedSpeed(multiplier){
      this.speed *= multiplier;
    };

    updateBalloonPosition(){
        if (this.state !== 'active') {
            return;
        }
        if (this.paused) {
            return;
        }
        this.el.style.bottom = (parseInt(this.el.style.bottom, 10)+(this.speed))+'px';
        if(parseInt(this.el.style.bottom)  > this.game.screenHeight){
            this.destroy();
            this.game.removeLife();
        }
        else{
            this.intervalId = requestAnimationFrame(this.updateBalloonPosition.bind(this));
        }
    }

    /**
     * Make sure the balloon spawns on a random X position
     */
    generateRandomXPos(){
        var canvasWidth = document.getElementById('canvas').offsetWidth;
        canvasWidth = canvasWidth - 106;

        return Math.floor(Math.random() * canvasWidth);
    };

    hit() {
        switch (this.color) {
            case 'red':
                this.color = 'orange';
                this.game.score += 2;
                this.game.updateScore(this.game.score);
                break;
            case 'orange':
                this.game.score += 1;
                this.game.updateScore(this.game.score);
                this.color = 'green';
                break;
            default:
                this.fullHit();
                break;

        }
        this.el.className = 'balloon ' + this.color;
    }

    fullHit() {
        this.game.score += this.points;
        this.game.updateScore(this.game.score);
        this.destroy();
    }

    /**
     * Spawn the balloon as an element on the screen.
     *
     * @param {int} multiplier
     */
    spawnOnCanvas(multiplier, balloonId){
        this.balloonId = balloonId;
        this.el = document.createElement('div');
        this.el.className = 'balloon '+ this.color;
        this.el.id = balloonId;
        this.el.style.left = this.positionX+'px';
        this.el.style.bottom = this.positionY+'px';
        var balloon = this;
        
        // Handle mouse down OR touch start

        setEvent(this.el, (event) => {
                event.preventDefault();
                event.stopImmediatePropagation();
                balloon.hit();
        })

        // if ('ontouchstart' in window) {
        //     this.el.addEventListener("touchstart", (event) => {
        //         event.preventDefault();
        //         event.stopImmediatePropagation();
        //         balloon.hit();
        //     }, true);
        //     this.el.addEventListener("touchmove", (event) => {
        //         event.stopImmediatePropagation();
        //         event.preventDefault();
        //     }, true);
        // } else {
        //     this.el.addEventListener("mousedown", (event) => {
        //         event.preventDefault();
        //         event.stopImmediatePropagation();
        //         balloon.hit();
        //     }, true);
        // }

        this.game.canvasElement.appendChild(this.el);
        this.state = 'active';
        this.setMultipliedSpeed(multiplier);

        // if(this.game.debug){
        //     this.el.className += ' debug';
        // }
        requestAnimationFrame(this.updateBalloonPosition.bind(this));
    }

    /**
     * Destroys the element.
     */
    destroy(){
        if (this.state === 'removed') {
            return;
        }
        this.state = 'removed';
        this.game.gameLog(this.el.className + ' destroyed! ID: '+ this.balloonId);
        cancelAnimationFrame(this.intervalId);
        this.game.canvasElement.removeChild(this.el);
        this.explosion = document.createElement('div');
        this.explosion.className = 'explosion'
        this.game.gameLog(this.el.style.left);
        this.explosion.style.left = (parseFloat(this.el.style.left) - 100) + 'px';
        this.explosion.style.bottom = (parseFloat(this.el.style.bottom) - 50) +'px';
        this.game.canvasElement.appendChild(this.explosion);

        this.explosionTimout = setTimeout(this.explode.bind(this),249);

    }

    explode(){
        this.game.canvasElement.removeChild(this.explosion);
        clearTimeout(this.explosionTimout);
    }

    pause(state: boolean) {
        this.paused = state;
        if (!this.paused) {
            requestAnimationFrame(this.updateBalloonPosition.bind(this));
        }

    }

}

export class GreenBalloon extends Balloon{
    constructor(game: Game){
        super(game);
        this.color = 'green';
        this.points = game.config.balloonScores[0];
        this.speed = 2;
    }

}

export class PinkBalloon extends Balloon{
    constructor(game){
        super(game);
        this.color = 'pink';
        this.points = game.config.balloonScores[1];
        this.speed = 3;
    }
}

export class OrangeBalloon extends Balloon{
    constructor(game){
        super(game);
        this.color = 'orange';
        this.points = game.config.balloonScores[2];
        this.speed = 4;
    }
}

export class RedBalloon extends Balloon{
    constructor(game){
        super(game);
        this.color = 'red';
        this.points = game.config.balloonScores[3];
        this.speed = 5;
    }
}