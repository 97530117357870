/**
 * Generic backgroundImage class
 */
type ImageTypes = 'planus' | 'cloud' | 'bird';

export class BackgroundImage{
    differntStartPos: any;
    positionX: number;
    positionY: number;
    speed: any;
    intervalId: any;
    game: any;
    onclick: any;
    el: any;
    leftToRight: any;
    type: ImageTypes;
    version: number;
    constructor(game, type: ImageTypes, speed, differntStartPos, version?: number){
        this.differntStartPos = differntStartPos;
        this.positionX = this.getLeftOrRightStartPos();
        this.positionY = this.generaterandomYPos();
        this.speed = speed;
        this.intervalId = null;
        this.game = game;
        this.onclick = null;
        this.el = null;
        this.leftToRight;
        this.type = type;
        this.version = version;
    }
  
    getLeftOrRightStartPos(){
        var startPos = 0;
        if(this.differntStartPos){
            var canvasWidth = document.getElementById('canvas').offsetWidth;
            canvasWidth -= 200;
            startPos =  Math.floor(Math.random() * canvasWidth);
        }else{
            var canvasWidth = document.getElementById('canvas').offsetWidth;
            var rand = Math.floor(Math.random() * Math.floor(2));
            
            switch(rand){
                case 0:
                    startPos = -557;
                    this.leftToRight = true;
                    break;
                case 1:
                    startPos = canvasWidth;
                    this.leftToRight = false;
                    break;
            }
        }
        return startPos;
    }

    generaterandomYPos(){
        var canvasHeight = document.getElementById('canvas').offsetHeight;
        canvasHeight = canvasHeight - 200;
        
        return Math.floor(Math.random() * canvasHeight);
    }

    updateIamgePosition(){
        var destroyed = false;
        var left = parseFloat(this.el.style.left);

        if(this.leftToRight){
            this.el.style.left = left + this.speed + 'px';   
            if(left > this.game.screenWidth){
                this.destroy();
                destroyed = true;
            }
        }
        else{
            this.el.style.left = left-this.speed + 'px';
            if(left < -557){
                this.destroy();
                destroyed = true;
            }
        }
        if(!destroyed){
            this.intervalId = requestAnimationFrame(this.updateIamgePosition.bind(this));
        }
    }

    /**
     * Spawn the image as an element on the screen.
     * 
     */
    spawnOnCanvas(){
        this.el = document.createElement('div');
        this.el.className = this.type + (this.version ? ' c' + this.version : '');
        this.game.gameLog('create', this.el.className);
        this.el.style.left = this.positionX+'px';
        this.el.style.bottom = this.positionY+'px';
        // this.el.addEventListener("touchstart", (event) => {
        //     event.preventDefault();
        //     event.stopImmediatePropagation();
        //     return;
        // }, false);
        this.game.canvasElement.appendChild(this.el);
        if(!this.leftToRight){
            this.el.className += ' rtl';
        }
        requestAnimationFrame(this.updateIamgePosition.bind(this));
      }

    /**
     * Destroys the element.
     */
    destroy(){
        this.game.gameLog(this.el.className + ' destroyed!');
        cancelAnimationFrame(this.intervalId);
        this.game.canvasElement.removeChild(this.el);
        var directSpawn = false;
        if (this.type.indexOf('cloud') > -1) {
            this.type = 'cloud';
            directSpawn = true;
          } 
        this.game.imageSpawner(this.type, 1, directSpawn, false);
    }
}
